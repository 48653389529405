import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {lastValueFrom, Observable} from 'rxjs';
import {
  DocxChanges,
  GetOCRProject,
  IScanMedia,
  KeywordsFound2,
  ScanProject,
  ScanProjectPdfOcrRequestBody,
  TranscriptDocumentDocxSection,
  TranscriptDocumentPDFSection,
  TranscriptSection,
  ScanProjectMultipleDocsRequestBody,
  TranscriptSectionOCR,
  ScanTextBody
} from "../common/interfaces/scan/scan.interface";
import {Endpoints} from '../endpoints';
import {keywordTemporaryTransformer} from "../helpers/keyword-temporary-transformer";
import {RatingLevelType} from "../common/interfaces/scan/website-scan-interface";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

export type KeywordDetail = {
  keyword_id: string,
  keyword_suggestions: string[],
  keyword_notes: string,
  scanProjectId: string;
};

@Injectable({
  providedIn: 'root',
})
export class ScanProjectService {
  constructor(private readonly http: HttpClient) {
  }

  scanProject(body: ScanProject): Observable<ScanProject> {
    return this.http.post<ScanProject>(Endpoints.scanProjects(), body);
  }

  getScanProjectObservable(id: string): Observable<any> {
    return this.http.get<any>(Endpoints.scanReportsProjects(id));
  }

  // TODO: Define response type
  scanOCRProject(body: ScanProjectPdfOcrRequestBody): Observable<any> {
    return this.http.post<ScanProject>(Endpoints.scanOCRProject(), body);
  }

  // TODO: Define types
  scanMultipleDocs(body: ScanProjectMultipleDocsRequestBody): Observable<{ scanProjectId: string }> {
    return this.http.post<any>(Endpoints.scanMultipleDocs(), body);
  }

  // TODO: Define types
  scanText(body: ScanTextBody): Observable<any> {
    return this.http.post<any>(Endpoints.scanText(), body);
  }

  scanText2(body: ScanTextBody): Observable<any> {
    return this.http.post<any>(`${environment.cfBackend}/projects/text`, body);
  }

  startDocumentScan(data: {
    firebaseFilePaths: string[],
    keywordIds: string[],
    name: string
  }) {
    return lastValueFrom(this.http.post<{ projectId: string }>(`${environment.cfBackend}/projects/documents`, data));
  }

  startYoutubeSingleVideoScan(data: {
    youtubeVideoUrl: string,
    keywordIds: string[],
    name: string
  }) {
    return lastValueFrom(this.http.post<{ projectId: string; videoId: string } | undefined>(`${environment.cfBackend}/projects/single-video`, data));
  }

  getVideoReport(id: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.videoReport(id))).then(r => {
      return r;
    });
  }

  getAudioReport(id: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.audioReport(id))).then(r => {
      return r;
    });
  }

  getScanProject(id: string, videoUrl?: string | null): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.scanReportsProjects(id) + (videoUrl != null ? `?videoUrl=${videoUrl}` : ''))).then(r => {
      r.keywordsFound = r.keywordsFound?.map((k: any) => {
        if ((k as KeywordsFound2).keyword != null) {
          return {...k, ...(k as KeywordsFound2).keyword};
        } else {
          return k;
        }
      }) ?? [];
      // //TODO: remove this when database does not contains invalid variances and exceptions anymore
      r.keywordsFound = r.keywordsFound?.map((k: any) => {
        return keywordTemporaryTransformer(k);
      });
      return r;
    });
  }

  authService = inject(AuthService);

  async generateReport(scanProjectId: string | null): Promise<boolean> {
    if (scanProjectId == null) {
      return false;
    }
    const ownerId = this.authService.getCurrentUser()?.uid ?? '';
    return lastValueFrom(this.http.get<any>(Endpoints.generateFileReport(scanProjectId, ownerId))).then(r => {
      return true;
    }).catch(() => {
      return false;
    });
  }

  generateMetaPostReport(scanProjectId: string, ownerId: string, postId: string): Observable<any> {
    return this.http.get<any>(Endpoints.generateMetaPostReport(scanProjectId, ownerId, postId));
  }

  updateMetaPostCaption(scanProjectId: string, postId: string, payload: {message: string}): Observable<any> {
    return this.http.post<any>(Endpoints.updateMetaPostCaption(scanProjectId, postId), payload);
  }

  getMetaTranscript(scanProjectId: string, postId: string, mediaType: string, mediaId?: string): Observable<any> {
    return this.http.get<any>(Endpoints.getMetaTranscript(scanProjectId, postId, mediaType, mediaId));
  }

  generateOCRReport(scanProjectId: string, ownerId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.generateOCRFileReport(scanProjectId, ownerId))).then(r => {
      return r;
    });
  }

  generateDocBatchReport(scanProjectId: string, ownerId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.generateDocBatchFileReport(scanProjectId, ownerId))).then(r => {
      return r;
    });
  }

  generateDocBatchReportSingleFile(scanProjectId: string, path: string, ownerId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.generateDocBatchFileSingleReport(scanProjectId, path, ownerId))).then(r => {
      return r;
    });
  }

  getWebsiteReport(id: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.websiteReport(id))).then(r => {
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        if ((k as KeywordsFound2).keyword != null) {
          return {...k, ...(k as KeywordsFound2).keyword};
        } else {
          return k;
        }
      });
      // //TODO: remove this when database does not contains invalid variances and exceptions anymore
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        return keywordTemporaryTransformer(k);
      });
      return r;
    });
  }

  getWebsiteKeywordDetail(scanProjectId: string, keywordId: string, query: string): Promise<{
    keywordDetail: KeywordDetail,
    pages: string[]
  }> {
    return lastValueFrom(this.http.get<any>(Endpoints.websiteKeywordDetail(scanProjectId, keywordId, query)));
  }

  getYoutubeChannelKeywordDetail(scanProjectId: string, keywordId: string): Promise<{
    keywordDetail: KeywordDetail,
    pages: string[]
  }> {
    return lastValueFrom(this.http.get<any>(`${environment.cfBackend}/reports/${scanProjectId}/youtube-channel/${keywordId}/details`));
  }

  getDocumentKeywordDetail(scanProjectId: string, keywordId: string): Promise<{
    keywordDetail: KeywordDetail,
    pages: string[]
  }> {
    return lastValueFrom(this.http.get<any>(`${environment.cfBackend}/reports/${scanProjectId}/documents/${keywordId}/details`));
  }

  geKeywordDetail(scanProjectId: string, type: string, keywordId: string): Promise<{
    keywordDetail: KeywordDetail,
    pages: string[]
  }> {
    return lastValueFrom(this.http.get<any>(`${environment.cfBackend}/reports/${scanProjectId}/${type}/${keywordId}/details`));
  }

  getOcrReport(id: string): Promise<GetOCRProject> {
    return lastValueFrom(this.http.get<any>(Endpoints.ocrReport(id))).then(r => {
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        if ((k as KeywordsFound2).keyword != null) {
          return {...k, ...(k as KeywordsFound2).keyword};
        } else {
          return k;
        }
      });
      r.keywordsFound = r.keywordsFound.map((k: any) => {
        return keywordTemporaryTransformer(k);
      });
      return r;
    })
  }

  postScanMedia(body: IScanMedia): Observable<{ projectId: string }> {
    return this.http.post<IScanMedia>(Endpoints.scanMedia(), body);
  }

  getVideoScanProjectTranscript(id: string, section: number): Promise<TranscriptSection[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('section', section);
    return lastValueFrom(
      this.http.get<TranscriptSection[]>(Endpoints.scanTranscript(id), {params: queryParams})
    );
  }

  getWebScanReportProjectTranscript(id: string, page: string): Promise<TranscriptSection[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('page', page);
    return lastValueFrom(
      this.http.get<TranscriptSection[]>(Endpoints.scanWebsiteReportTranscript(id), {
        params: queryParams,
      })
    );
  }

  postDocChanges(body: DocxChanges, type: 'docx' | 'xlsx'): Promise<any> {
    return lastValueFrom(this.http.post<any>(Endpoints.replace(type), body));
  }

  editProjectName(projectId: string, projectName: string): Promise<any> {
    const body = {name: projectName};
    return lastValueFrom(this.http.patch<any>(Endpoints.editOrDeleteReport(projectId), body));
  }

  deleteProject(projectId: string): Promise<any> {
    return lastValueFrom(this.http.delete<any>(Endpoints.editOrDeleteReport(projectId)));
  }

  deleteMultipleProjects(projectIds: string[]): Promise<any> {
    return lastValueFrom(this.http.post<any>(Endpoints.deleteMultiple(), {projectIds}));
  }

  getScanProjectById(projectId: string): Promise<any> {
    return lastValueFrom(this.http.get<any>(Endpoints.getScanProjectById(projectId)));
  }

  cancelScan(projectId: string): Promise<any> {
    return lastValueFrom(this.http.delete(Endpoints.cancelScan(projectId)));
  }
}
