<mat-form-field appearance="outline" class="search-bar-form-field">
  <mat-icon matPrefix svgIcon="search-bar" class="search-bar-icon"></mat-icon>
  <mat-label>{{ searchBarLabel }}</mat-label>
  <input 
    type="search" 
    matInput 
    [formControl]="searchControl" 
    *ngIf="searchControl; else modelInput"
    maxlength="256">
  
  <ng-template #modelInput>
    <input 
      type="search" 
      matInput 
      [(ngModel)]="searchParams" 
      (keyup)="sendInputValue()" 
      maxlength="256">
  </ng-template>
</mat-form-field>
