export const environment = {
  environmentName: 'development',
  production: false,
  baseUrl: 'https://api-dev.apexcomplianceprogram.com/api/v1',
  baseOrganizationsUrl: 'https://organizations-dev-77510476034.us-central1.run.app',
  baseMetaUrl: 'https://apex-meta-processor-dev-77510476034.us-central1.run.app',
  stripeCustomerPortalUrl: 'https://billing.stripe.com/p/login/test_5kAbIR8LT8lzelidQQ',
  firebase: {
    apiKey: 'AIzaSyAthESTR8a-5uCPsThBLgHlgho2Eeo8o_A',
    authDomain: 'keyword-finder-platform.firebaseapp.com',
    projectId: 'keyword-finder-platform',
    storageBucket: 'keyword-finder-platform.appspot.com',
    messagingSenderId: '77510476034',
    appId: '1:77510476034:web:847578566553d9c417c21b',
    measurementId: 'G-PBQWDX2E2J',
  },
  ocrBucket: 'gs://apex-ocr-dev',
  fileBucket: 'gs://apex-files-scan-dev',
  metaBucket: 'gs://apex-meta-scan-dev',
  metaInstagramAppConfigId: '518546567322655',
  metaFacebookAppConfigId: '639439265193135',
  cfBackend: 'https://apex-platform-backend-development.apex-devops.workers.dev',
  r2Backend: 'https://apex-r2-worker-development.apex-devops.workers.dev',
  reportsBackend: 'https://apex-file-report-development.apex-devops.workers.dev'
};
