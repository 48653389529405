/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Endpoints } from '../endpoints';
import { CreateUserInterface } from '../common/interfaces/user/create-user.interface';
import { ProfileService } from './profile.service';
import { OrganizationService } from './organization.service';
import { FirstLoginMethod } from '../common/interfaces/user/user.interface';

interface forgotPassword {
  token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any;

  $authChanged: BehaviorSubject<string | null | undefined> = new BehaviorSubject<
    string | null | undefined
  >(null);

  constructor(
    public http: HttpClient,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private profileService: ProfileService,
    private organizationService: OrganizationService
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.setUserToLocalStorage(user);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  setUserToLocalStorage(user: any): void {
    const currentOrg = this.getCurrentOrganization();
    const credentials = this.getFirstLoginCredentials();
    const nextSubscriptionCheck = localStorage.getItem('nextSubscriptionCheck');
    localStorage.removeItem('currentOrgId');
    localStorage.removeItem('nextSubscriptionCheck');
    localStorage.removeItem('user');
    localStorage.removeItem('role');

    if (currentOrg) {
      this.setCurrentOrganization(currentOrg);
    }

    if(credentials) {
      this.setFirstLoginCredentials(credentials);
    }

    if(nextSubscriptionCheck) {
      localStorage.setItem('nextSubscriptionCheck', nextSubscriptionCheck);
    }

    this.$authChanged.next(user.email);
    this.userData = user;
    localStorage.setItem('user', JSON.stringify(this.userData));
    JSON.parse(localStorage.getItem('user')!);
  }

  async createUser(user: CreateUserInterface): Promise<CreateUserInterface> {
    const response = await lastValueFrom(this.http.post<CreateUserInterface>(Endpoints.createUser, user));
    return response;
  }

  SignIn(email: string, password: string, firstLoginMethod: FirstLoginMethod = FirstLoginMethod.FALSE): Promise<void> {
    return this.afAuth.signInWithEmailAndPassword(email, password).then((result) => {
      this.afAuth.authState.subscribe(async (user) => {
        this.setUserToLocalStorage(user);
        if (user) {
          switch (firstLoginMethod) {
            case FirstLoginMethod.CHANGE_PASSWORD:
              this.router.navigate(['profile/change-password'], {
                queryParams: {
                  firstLogin: true,
                  currentPassword: encodeURIComponent(password),
                },
              });
              return;

            case FirstLoginMethod.SUBSCRIBE:
              this.router.navigate(['/subscription'], { queryParams: { modal: true } });
              return;

            case FirstLoginMethod.FALSE:
            default:
              const route = await this.isAdmin() ? '/users/users' : '/dashboard';
              void this.router.navigate([route]);
              return;
          }
        }
      });
    });
  }
  SignInWithToken(token: string): Promise<void> {
    return this.afAuth.signInWithCustomToken(token).then((result) => {
      this.afAuth.authState.subscribe((user) => {
        this.setUserToLocalStorage(user);
        if (user) {
          void this.router.navigate(['/dashboard']);
        }
      });
    });
  }

  forgotPassword(email: string): Promise<forgotPassword> {
    return lastValueFrom(
      this.http.post(`${Endpoints.forgotPassword}`, {
        email,
        returnToken: true,
      })
    );
  }

  changePassword(password: string, token: string): Promise<object> {
    const headers = new HttpHeaders({ 'x-kwf-reset-password-token': token });
    return lastValueFrom(
      this.http.post(
        `${Endpoints.changePassword}`,
        {
          password,
        },
        {
          headers,
        }
      )
    );
  }

  SignOut(error = undefined): Promise<void> {
    this.$authChanged.next(null);
    window.localStorage.removeItem('nextSubscriptionCheck');
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('currentOrg')
      localStorage.removeItem('user');
      void this.router.navigate(['/login', {error}]);
    });
  }

  isAdmin(): Promise<boolean> {
    return this.profileService.getUser()
      .then((user: any) => {
        return user.isAdmin;
      })
      .catch(error => {
        return false;
      });
  }

  isOrganizationAdmin(organizationId: string): Promise<boolean> {
    const userId = JSON.parse(<string>localStorage.getItem('user')).uid;
    return this.organizationService.getUserRole(organizationId, userId).then((response: {role: string}) => {
      return response.role === 'admin' || response.role === 'owner';
    });
  }

  setCurrentOrganization(organizationId: string): void {
    localStorage.setItem('currentOrgId', organizationId);
  }

  getCurrentOrganization(): string | null {
    return localStorage.getItem('currentOrgId');
  }

  setFirstLoginCredentials(credentials: string): void {
    localStorage.setItem('credentials', credentials);
  }

  getFirstLoginCredentials(): string | null {
    return localStorage.getItem('credentials');
  }

  setCurrentRole(role: string): void {
    localStorage.setItem('role', role);
  }

  getCurrentRole(): string | null {
    return localStorage.getItem('role');
  }

  getCurrentUser(): any {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user;
  }
}
