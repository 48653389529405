import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent {
  @Output() action = new EventEmitter();
  @Input() searchBarLabel: string = '';
  @Input() searchControl?: FormControl;
  searchParams: string = '';

  sendInputValue(): void {
    if (!this.searchControl) {
      this.action.emit(this.searchParams);
    }
  }
}
